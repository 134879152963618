import React, { useEffect } from 'react';
import { useGTM, useUserProfile } from '@didomi/utility-react';
import { PromotionalPage } from '@pages';

const App = () => {
  const [userProfile] = useUserProfile();
  const { pushEventToDataLayer } = useGTM();

  useEffect(() => {
    if (userProfile?.self_register) {
      pushEventToDataLayer('product-version', {
        product: 'self-service',
        page_path: 'self-service-preview',
      });
    }
  }, [userProfile, pushEventToDataLayer]);

  return <PromotionalPage />;
};

export { App };
