import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import {
  DidomiButton,
  DidomiChip,
  DidomiIcon,
  DidomiPromotionalPush,
} from '@didomi/ui-atoms-react';

import { PromotionalPageBanner } from '@components';
import { SPA_FULL_NAME } from '@constants';

const PromotionalPage = (): JSX.Element => {
  const ASSETS_URL = useSPAAssetsUrl(SPA_FULL_NAME);

  return (
    <div className="h-full w-full flex flex-row items-center bg-light-blue p-l">
      <div className="flex flex-col w-full h-full mx-auto gap-y-l">
        <div className="w-full grow flex flex-row items-center">
          <div className="ml-20 w-5/12 max-w-2xl flex flex-col justify-center">
            <DidomiChip variation="basic" basicType="error" label="New product" />
            <div className="mt-s uppercase text-body-big font-semibold text-primary-blue-6">
              Server-side hosting
            </div>
            <h1 className="my-xs h1-catchline-medium text-primary-blue-6">
              Optimize your data strategy
            </h1>
            <ul className="mt- font-sans text-body-normal text-primary-blue-6 mb-xxs space-y-xxxs">
              <li className="flex items-center gap-x-3 text-body-normal">
                <DidomiIcon name="step-success" className="h-4 w-4" />
                <span>Improve security and control over data</span>
              </li>
              <li className="flex items-center gap-x-3">
                <DidomiIcon name="step-success" className="h-4 w-4" />
                <span>Improve website performance</span>
              </li>
              <li className="flex items-center gap-x-3">
                <DidomiIcon name="step-success" className="h-4 w-4" />
                <span>Improve return on ad spend and CPM</span>
              </li>
            </ul>

            <DidomiButton
              variant="main"
              size="medium"
              onClick={() => {}}
              data-skeleton
              className="mt-l"
            >
              Contact us
            </DidomiButton>
          </div>
          <div className="w-7/12 flex h-full items-center justify-start">
            <PromotionalPageBanner className="" />
          </div>
        </div>

        <DidomiPromotionalPush variant="light">
          <img
            slot="img"
            src={`${ASSETS_URL}/assets/cta-image-profile.png`}
            alt=""
            className="h-[5.375rem] m-auto rounded-full border-[.3837rem] border-white"
          />

          <div slot="title">Interested in our Server-side hosting solutions?</div>
          <div slot="description">
            Reach out to your Account Manager to explore the benefits of our Server-side hosting
            solutions!
          </div>

          <DidomiButton slot="cta" variant="secondary-light" size="medium" onClick={() => {}}>
            Contact us
          </DidomiButton>
        </DidomiPromotionalPush>
      </div>
    </div>
  );
};

export { PromotionalPage };
